<template>
  <div class="aali_tm_section" id="service">
    <div class="aali_tm_service">
      <div class="container">
        <div class="service_list">
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner">
                <div
                  class="aali_tm_main_title"
                  data-text-align="left"
                  data-color="dark"
                >
                  <span>What I Do</span>
                  <h3>My Services</h3>
                  <p>
                    We offer high quality products for competitive prices. Our
                    main goal is customer satisfaction, which we obtain through
                    market orientation of ongoing service and support
                  </p>
                </div>
              </div>
            </li>

            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/infinity2.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>

                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/camera.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>

                <div class="title">
                  <h3>Continuous Integration and Continuous Delivery (CI/CD)</h3>
                  <span class="price">Starts from <span>$69</span></span>
                </div>
                <div class="text">
                  <p>
                    Helping clients set up automated build, test, 
                    and deployment pipelines to accelerate software
                    delivery and improve code quality.
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 1"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/1.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        With our CI/CD Implementation services, we help clients revolutionize their software development process. By setting up automated build, test, and deployment pipelines, we enable faster and more reliable software delivery.  
                      </p>
                      <p>
                        Continuous integration ensures that code changes are regularly integrated and tested, promoting collaboration among development teams. Continuous delivery ensures that validated code changes can be easily and reliably deployed to production environments, reducing time-to-market and enhancing code quality.
                      </p>
                      <p>
                        Our expertise in CI/CD tools and best practices ensures that clients can embrace agile development methodologies with confidence, staying ahead in today's competitive digital landscape.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 1 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/1.jpg"
                      style="background-image: url('img/service/1.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Continuous Integration and Continuous Delivery (CI/CD)</h3>
                    <span class="price">Starts from <span>$69</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        With our CI/CD Implementation services, we help clients revolutionize their software development process. By setting up automated build, test, and deployment pipelines, we enable faster and more reliable software delivery.  
                      </p>
                      <p>
                        Continuous integration ensures that code changes are regularly integrated and tested, promoting collaboration among development teams. Continuous delivery ensures that validated code changes can be easily and reliably deployed to production environments, reducing time-to-market and enhancing code quality.
                      </p>
                      <p>
                        Our expertise in CI/CD tools and best practices ensures that clients can embrace agile development methodologies with confidence, staying ahead in today's competitive digital landscape.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>


            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/terraform.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Infrastructure as Code (IaC) Development</h3>
                  <span class="price">Starts from <span>$99</span></span>
                </div>
                <div class="text">
                  <p>
                    Assisting clients in managing and provisioning infrastructure using tools like Terraform, Ansible, or CloudFormation to ensure consistency and scalability
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 2"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Our Infrastructure as Code (IaC) Development services empower clients to manage their infrastructure more effectively and efficiently.  
                      </p>
                      <p>
                        We leverage industry-leading tools like Terraform, Ansible, and CloudFormation to help clients provision and configure their infrastructure through code, promoting consistency and scalability.
                      </p>
                      <p>
                        By automating infrastructure provisioning, clients can easily recreate and scale environments, saving time and reducing human errors. Whether it's setting up on-premises infrastructure or deploying resources on cloud platforms like AWS, Azure, or Google Cloud, our IaC expertise ensures a robust foundation for clients' applications and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 2 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Infrastructure as Code (IaC) Development</h3>
                    <span class="price">Starts from <span>$59</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Our Infrastructure as Code (IaC) Development services empower clients to manage their infrastructure more effectively and efficiently.  
                      </p>
                      <p>
                        We leverage industry-leading tools like Terraform, Ansible, and CloudFormation to help clients provision and configure their infrastructure through code, promoting consistency and scalability.
                      </p>
                      <p>
                        By automating infrastructure provisioning, clients can easily recreate and scale environments, saving time and reducing human errors. Whether it's setting up on-premises infrastructure or deploying resources on cloud platforms like AWS, Azure, or Google Cloud, our IaC expertise ensures a robust foundation for clients' applications and services.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>





            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/cloud.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Cloud Migration and Management</h3>
                  <span class="price">Starts from <span>$99</span></span>
                </div>
                <div class="text">
                  <p>
                    Supporting businesses in migrating their applications and data to cloud platforms like AWS, Azure, Google Cloud, etc., and providing ongoing cloud infrastructure management
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 3"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Navigating the complexities of cloud migration is made seamless with our Cloud Migration and Management services. Our team of cloud experts assists businesses in smoothly transitioning their applications and data to leading cloud platforms like AWS, Azure, Google Cloud, and more.  
                      </p>
                      <p>
                        From assessing the existing infrastructure to designing a well-architected cloud environment, we ensure a secure, cost-effective, and scalable cloud solution tailored to our clients' unique needs.
                      </p>
                      <p>
                        Additionally, our ongoing cloud infrastructure management services provide continuous monitoring, optimization, and cost control, allowing clients to focus on innovation and core business operations while we handle the cloud infrastructure with diligence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 3 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Cloud Migration and Management</h3>
                    <span class="price">Starts from <span>$99</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Navigating the complexities of cloud migration is made seamless with our Cloud Migration and Management services. Our team of cloud experts assists businesses in smoothly transitioning their applications and data to leading cloud platforms like AWS, Azure, Google Cloud, and more.  
                      </p>
                      <p>
                        From assessing the existing infrastructure to designing a well-architected cloud environment, we ensure a secure, cost-effective, and scalable cloud solution tailored to our clients' unique needs.
                      </p>
                      <p>
                        Additionally, our ongoing cloud infrastructure management services provide continuous monitoring, optimization, and cost control, allowing clients to focus on innovation and core business operations while we handle the cloud infrastructure with diligence.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>





            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/kubernetes.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Containerization and Orchestration - Docker | Kubernetes</h3>
                  <span class="price">Starts from <span>$99</span></span>
                </div>
                <div class="text">
                  <p>
                    Implementing containerization using Docker and container orchestration with Kubernetes for efficient application deployment and scaling
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 4"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Our Containerization and Orchestration services harness the power of Docker and Kubernetes to simplify application deployment and scaling. By containerizing applications, we encapsulate them with all their dependencies, ensuring consistency and portability across various environments. 
                      </p>
                      <p>
                        The implementation of Kubernetes further automates the management of containers, enabling efficient orchestration, scaling, and load balancing of applications. 
                      </p>
                      <p>
                        With our expertise in container technologies, clients can experience faster development cycles, simplified deployment processes, and improved resource utilization, making their infrastructure more agile and responsive to changing business demands.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 4 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Containerization and Orchestration - Docker | Kubernetes</h3>
                    <span class="price">Starts from <span>$99</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Our Containerization and Orchestration services harness the power of Docker and Kubernetes to simplify application deployment and scaling. By containerizing applications, we encapsulate them with all their dependencies, ensuring consistency and portability across various environments. 
                      </p>
                      <p>
                        The implementation of Kubernetes further automates the management of containers, enabling efficient orchestration, scaling, and load balancing of applications. 
                      </p>
                      <p>
                        With our expertise in container technologies, clients can experience faster development cycles, simplified deployment processes, and improved resource utilization, making their infrastructure more agile and responsive to changing business demands.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/monitor3.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Monitoring and Alerting</h3>
                  <span class="price">Starts from <span>$59</span></span>
                </div>
                <div class="text">
                  <p>
                    Setting up monitoring solutions to track application and infrastructure performance, and configuring alerting systems to notify about potential issues
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 5"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Building resilient systems starts with comprehensive Monitoring and Alerting solutions. Our services involve setting up robust monitoring systems that continuously track the performance of applications and infrastructure. 
                      </p>
                      <p>
                        By employing industry-leading monitoring tools, we gain insights into system health, resource utilization, and application metrics. We configure smart alerting mechanisms that promptly notify relevant stakeholders about potential issues, ensuring quick response and minimizing downtime. 
                      </p>
                      <p>
                        With our Monitoring and Alerting services, clients gain the confidence to deliver high-quality, reliable services, while staying ahead of potential problems in real-time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 5 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Monitoring and Alerting</h3>
                    <span class="price">Starts from <span>$59</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Building resilient systems starts with comprehensive Monitoring and Alerting solutions. Our services involve setting up robust monitoring systems that continuously track the performance of applications and infrastructure. 
                      </p>
                      <p>
                        By employing industry-leading monitoring tools, we gain insights into system health, resource utilization, and application metrics. We configure smart alerting mechanisms that promptly notify relevant stakeholders about potential issues, ensuring quick response and minimizing downtime. 
                      </p>
                      <p>
                        With our Monitoring and Alerting services, clients gain the confidence to deliver high-quality, reliable services, while staying ahead of potential problems in real-time.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/auto1.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>DevOps Automation</h3>
                  <span class="price">Starts from <span>$69</span></span>
                </div>
                <div class="text">
                  <p>
                    Automating repetitive tasks, such as testing, deployment, and infrastructure provisioning, to enhance productivity and reduce manual errors
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 6"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Embrace efficiency and accuracy with our DevOps Automation services. We identify repetitive and time-consuming tasks across the software development lifecycle and automate them to enhance productivity. 
                      </p>
                      <p>
                        From automated testing to deployment pipelines and infrastructure provisioning, our automation solutions reduce manual errors and accelerate the delivery process. By streamlining the development workflow, teams can focus on innovation, while our automation expertise ensures consistent and reliable results, no matter the scale or complexity of the project.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 6 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>DevOps Automation</h3>
                    <span class="price">Starts from <span>$69</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Embrace efficiency and accuracy with our DevOps Automation services. We identify repetitive and time-consuming tasks across the software development lifecycle and automate them to enhance productivity. 
                      </p>
                      <p>
                        From automated testing to deployment pipelines and infrastructure provisioning, our automation solutions reduce manual errors and accelerate the delivery process. By streamlining the development workflow, teams can focus on innovation, while our automation expertise ensures consistent and reliable results, no matter the scale or complexity of the project.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/security.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Security and Compliance</h3>
                  <span class="price">Starts from <span>$69</span></span>
                </div>
                <div class="text">
                  <p>
                    Implementing security best practices in CI/CD pipelines and ensuring compliance with industry standards and regulations
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 7"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Ensuring the security and compliance of software delivery is a top priority in our DevOps practice. With a keen focus on security best practices, we integrate robust security measures into the CI/CD pipelines. 
                      </p>
                      <p>
                        From code scanning and vulnerability assessments to access controls and encryption, we safeguard clients' applications and data against potential threats. Our approach includes compliance checks to meet industry standards and regulations, ensuring that clients can confidently operate within their respective domains while maintaining the highest security standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 7 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Security and Compliance</h3>
                    <span class="price">Starts from <span>$69</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Ensuring the security and compliance of software delivery is a top priority in our DevOps practice. With a keen focus on security best practices, we integrate robust security measures into the CI/CD pipelines. 
                      </p>
                      <p>
                        From code scanning and vulnerability assessments to access controls and encryption, we safeguard clients' applications and data against potential threats. Our approach includes compliance checks to meet industry standards and regulations, ensuring that clients can confidently operate within their respective domains while maintaining the highest security standards.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/optimization.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Performance Optimization</h3>
                  <span class="price">Starts from <span>$59</span></span>
                </div>
                <div class="text">
                  <p>
                    Analyzing and optimizing application and infrastructure performance to enhance user experience and reduce response times
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 8"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Unleash the full potential of your applications and infrastructure with our Performance Optimization services. Our team of experts conducts in-depth performance analysis, identifying bottlenecks and areas for improvement. By fine-tuning configurations, optimizing database queries, and implementing caching strategies, we enhance application responsiveness and reduce response times. With our focus on performance optimization, clients can deliver an exceptional user experience, leading to higher user satisfaction and increased business success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 8 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Performance Optimization</h3>
                    <span class="price">Starts from <span>$59</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Unleash the full potential of your applications and infrastructure with our Performance Optimization services. Our team of experts conducts in-depth performance analysis, identifying bottlenecks and areas for improvement. By fine-tuning configurations, optimizing database queries, and implementing caching strategies, we enhance application responsiveness and reduce response times. With our focus on performance optimization, clients can deliver an exceptional user experience, leading to higher user satisfaction and increased business success.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/git.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Version Control and Collaboration</h3>
                  <span class="price">Starts from <span>$49</span></span>
                </div>
                <div class="text">
                  <p>
                    Helping teams adopt version control systems like Git and implementing collaborative tools to improve development efficiency
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 9"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Seamless collaboration and version control are fundamental to successful software development. Our services help clients adopt version control systems like Git, enabling teams to collaborate efficiently on code development. We implement workflows that ensure code changes are tracked, reviewed, and merged seamlessly, fostering collaboration and minimizing conflicts. With version control in place, clients can confidently manage code changes, track progress, and maintain a clear history of their projects, enabling greater visibility and control over the development process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 9 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Version Control and Collaboration</h3>
                    <span class="price">Starts from <span>$49</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                      Seamless collaboration and version control are fundamental to successful software development. Our services help clients adopt version control systems like Git, enabling teams to collaborate efficiently on code development. We implement workflows that ensure code changes are tracked, reviewed, and merged seamlessly, fostering collaboration and minimizing conflicts. With version control in place, clients can confidently manage code changes, track progress, and maintain a clear history of their projects, enabling greater visibility and control over the development process.
                    </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/recovery.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Incident Management and Recovery</h3>
                  <span class="price">Starts from <span>$99</span></span>
                </div>
                <div class="text">
                  <p>
                    Developing incident response plans and strategies to quickly address and recover from system failures or security breaches
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 10"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        Be prepared for any eventuality with our Incident Management and Recovery services. We develop comprehensive incident response plans tailored to each client's infrastructure and business requirements. 
                      </p>
                      <p>
                        In the event of a system failure or security breach, our well-defined strategies guide clients through a swift and organized recovery process. Our team of experts ensures that clients can quickly identify and mitigate issues, reducing the impact of incidents on business operations and maintaining high availability and security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 10 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/3.jpg"
                      style="background-image: url('img/service/3.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Incident Management and Recovery</h3>
                    <span class="price">Starts from <span>$99</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        Be prepared for any eventuality with our Incident Management and Recovery services. We develop comprehensive incident response plans tailored to each client's infrastructure and business requirements. 
                      </p>
                      <p>
                        In the event of a system failure or security breach, our well-defined strategies guide clients through a swift and organized recovery process. Our team of experts ensures that clients can quickly identify and mitigate issues, reducing the impact of incidents on business operations and maintaining high availability and security.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>



            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/pen-dark.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-else>
                  <img class="svg" src="img/svg/pen.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>DevOps Consulting and Strategy</h3>
                  <span class="price">Starts from <span>$69</span></span>
                </div>
                <div class="text">
                  <p>
                    Offering guidance on adopting DevOps methodologies and devising custom DevOps strategies tailored to clients' specific needs.
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 11"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/2.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        For clients seeking guidance in adopting DevOps methodologies, our DevOps Consulting and Strategy services offer valuable insights and custom-tailored strategies. 
                      </p>
                      <p>
                        Our team collaborates closely with clients to understand their business goals and challenges. Based on the assessment, we design comprehensive DevOps strategies that align with clients' unique requirements and organizational culture.
                      </p>
                      <p>
                        Through our consulting services, clients can navigate the complexities of adopting DevOps seamlessly and ensure a successful transformation that leads to improved collaboration, faster delivery, and enhanced software quality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div :class="`${active === 11 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/2.jpg"
                      style="background-image: url('img/service/2.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>DevOps Consulting and Strategy</h3>
                    <span class="price">Starts from <span>$69</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                        For clients seeking guidance in adopting DevOps methodologies, our DevOps Consulting and Strategy services offer valuable insights and custom-tailored strategies. 
                      </p>
                      <p>
                        Our team collaborates closely with clients to understand their business goals and challenges. Based on the assessment, we design comprehensive DevOps strategies that align with clients' unique requirements and organizational culture.
                      </p>
                      <p>
                        Through our consulting services, clients can navigate the complexities of adopting DevOps seamlessly and ensure a successful transformation that leads to improved collaboration, faster delivery, and enhanced software quality.
                      </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/training.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-else>
                  <img class="svg" src="img/svg/user.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Training and Workshops with Network and System Administrator Support</h3>
                  <span class="price">Starts from <span>$59</span></span>
                </div>
                <div class="text">
                  <p>
                    In addition to providing comprehensive training and workshops on DevOps practices and tools, we also offer expert Network and System Administrator Support. Our team of skilled administrators ensures optimal performance, security, and reliability of your IT infrastructures
                  </p>
                </div>
                <a
                  class="aali_tm_full_link"
                  href="#"
                  @click.prevent="active = 12"
                ></a>
                <img
                  class="popup_service_image"
                  src="img/service/4.jpg"
                  alt=""
                />
              </div>
            </li>
            <div :class="`${active === 12 ? '' : 'service_hidden_details'}`">
              <ModalBox :close="close">
                <div class="service_popup_informations">
                  <div class="image">
                    <!-- <img src="img/thumbs/4-2.jpg" alt="" /> -->
                    <div
                      class="main"
                      data-img-url="img/service/4.jpg"
                      style="background-image: url('img/service/4.jpg')"
                    ></div>
                  </div>
                  <div class="main_title">
                    <h3>Training and Workshops with Network and System Administrator Support</h3>
                    <span class="price">Starts from <span>$59</span></span>
                  </div>
                  <div class="descriptions">
                    <p>
                      Empower your development and operations teams with our Training and Workshops. We conduct hands-on training sessions and workshops that cover various aspects of DevOps practices and tools. From CI/CD best practices to containerization and cloud management, our sessions help teams acquire essential skills to excel in their roles. We tailor our training programs to suit clients' specific needs and provide practical insights to foster a culture of continuous learning and improvement.
                    </p>
                    <p>
                      We provide reliable Network and System Administrator Support to ensure clients' IT infrastructures operate at peak performance. Our team of skilled administrators handles all aspects of server setup, configuration, and maintenance. We implement proactive network monitoring solutions that constantly oversee network health and performance, allowing us to identify and resolve potential issues before they escalate. Additionally, our expertise in troubleshooting and robust backup and recovery solutions guarantees that clients' systems remain secure and resilient to any unforeseen events. With our Network and System Administrator Support, clients can rest assured that their IT operations are in capable hands, allowing them to focus on their core business objectives without worry.
                    </p>
                    <p>
                      From server setup and configuration to network monitoring, troubleshooting, and robust backup solutions, we handle the essential aspects of IT infrastructure management, allowing you to focus on innovation and core business operations with confidence. Whether you need to upskill your teams or seek reliable IT support, our integrated services have you covered.
                    </p>
                  </div>
                </div>
              </ModalBox>
            </div>




            <li
              class="simple text wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="wrapper">
                  <div class="inner_text">
                    <h3>Do You Want Something Different?</h3>
                  </div>
                  <div class="aali_tm_button border">
                    <a class="anchor" href="#contact"
                      ><span v-html="`Knock Me ` + msg"></span
                    ></a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <span
        class="square_left moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
      <span
        class="square_right moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import ModalBox from "./ModalBox.vue";
import { msg } from "./svgImage";
export default {
  name: "Service",
  data() {
    return {
      msg,
      active: 0,
    };
  },
  props: {
    dark: Boolean,
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
};
</script>
