<template>
  <div id="preloader">
    <div class="loader_line"></div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>
