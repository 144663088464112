<template>
  <div class="aali_tm_section">
    <div class="aali_tm_resume">
      <div class="content">
        <div class="container">
          <div class="resume_in">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="dark"
            >
              <span>Know More</span>
              <h3>My Resume</h3>
              <p>
                I enjoy every step of the design process, from discussion and
                collaboration to concept and execution
              </p>
            </div>
            <div class="content_inner">
              <ul>
                <li class="wow fadeInLeft" data-wow-duration="1s">
                  <h3 class="main_title"><span>Education</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>BS Telecom & Networking</h3>
                            <span>COMSATS University</span>
                            <br>
                            <span>Silver Medalist</span>
                          </div>
                          <div class="right">
                            <span>2013 - 2017</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            An in-depth study of computer networks with a focus on research and practical experience.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>HSSC</h3>
                            <span>Divisional Public College</span>
                          </div>
                          <div class="right">
                            <span>2011 - 2012</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Specialized coursework to explore and develop expertise in chosen subjects
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>SSC</h3>
                            <span>Divisional Public School</span>
                          </div>
                          <div class="right">
                            <span>2009 - 2010</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Focus on building a strong academic foundation and developing critical thinking skills
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>



                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title"><span>Experience</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Senior DevOps Engineer</h3>
                            <a
                              class="line_anim"
                              href="https://beyonmoney.com"
                              target="_blank"
                              ><span>BEYON Money  -  FINTECH</span></a>
                          </div>
                          <div class="right">
                            <span>2022 - running</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Strong experience with AWS Cloud infrastructure, including deployment on ECS, EKS, and EC2 instances.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Senior DevOps Engineer</h3>
                            <a
                              class="line_anim"
                              href="https://Digitify.com"
                              target="_blank">
                              <span>Digitify & YAP  -  FINTECH</span></a>
                          </div>
                          <div class="right">
                            <span>2021 - 2022</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Proficient in Infrastructure as Code automation using Terraform and Bash/Shell scripting, and skilled in managing Docker containers for application lifecycle management.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>DevOps Engineer</h3>
                            <a
                              class="line_anim"
                              href="https://cognitivehealthintl.com/"
                              target="_blank">
                            <span>Cognitive Healthcare International</span></a>
                          </div>
                          <div class="right">
                            <span>2019 - 2021</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
Remote healthcare software solution that facilitates seamless virtual consultations, remote patient monitoring, and secure medical data exchange, ensuring efficient and accessible healthcare services from the comfort of patients' homes.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="media" data-background-style="video">
        <!-- Background Styles: "video" and "image" // Also you can use any youtube, vimeo, and local videos -->
        <div
          class="video jarallax"
          data-speed="0"
          data-jarallax-video="https://www.youtube.com/watch?v=DFf37yQHDn8"
        ></div>
        <div
          class="image jarallax"
          data-speed="0"
          data-img-url="img/about/2.jpg"
        ></div>
        <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { jarallaxContent } from "../utils/utils";
import { edu, exp } from "./svgImage";

export default {
  name: "Resume",
  data() {
    return {
      edu,
      exp,
    };
  },
  mounted() {
    jarallaxContent();
  },
};
</script>
