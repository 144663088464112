var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_section"},[_c('div',{staticClass:"aali_tm_resume"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"resume_in"},[_vm._m(0),_c('div',{staticClass:"content_inner"},[_c('ul',[_c('li',{staticClass:"wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_vm._m(1),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(2),_vm._m(3)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(4),_vm._m(5)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(6),_vm._m(7)])])])]),_c('li',{staticClass:"wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_vm._m(8),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(9),_vm._m(10)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(11),_vm._m(12)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(13),_vm._m(14)])])])])])])])])]),_vm._m(15)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_main_title",attrs:{"data-text-align":"left","data-color":"dark"}},[_c('span',[_vm._v("Know More")]),_c('h3',[_vm._v("My Resume")]),_c('p',[_vm._v(" I enjoy every step of the design process, from discussion and collaboration to concept and execution ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Education")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("BS Telecom & Networking")]),_c('span',[_vm._v("COMSATS University")]),_c('br'),_c('span',[_vm._v("Silver Medalist")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2013 - 2017")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" An in-depth study of computer networks with a focus on research and practical experience. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("HSSC")]),_c('span',[_vm._v("Divisional Public College")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2011 - 2012")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Specialized coursework to explore and develop expertise in chosen subjects ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("SSC")]),_c('span',[_vm._v("Divisional Public School")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2009 - 2010")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Focus on building a strong academic foundation and developing critical thinking skills ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Experience")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Senior DevOps Engineer")]),_c('a',{staticClass:"line_anim",attrs:{"href":"https://beyonmoney.com","target":"_blank"}},[_c('span',[_vm._v("BEYON Money - FINTECH")])])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2022 - running")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Strong experience with AWS Cloud infrastructure, including deployment on ECS, EKS, and EC2 instances. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Senior DevOps Engineer")]),_c('a',{staticClass:"line_anim",attrs:{"href":"https://Digitify.com","target":"_blank"}},[_c('span',[_vm._v("Digitify & YAP - FINTECH")])])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2021 - 2022")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Proficient in Infrastructure as Code automation using Terraform and Bash/Shell scripting, and skilled in managing Docker containers for application lifecycle management. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("DevOps Engineer")]),_c('a',{staticClass:"line_anim",attrs:{"href":"https://cognitivehealthintl.com/","target":"_blank"}},[_c('span',[_vm._v("Cognitive Healthcare International")])])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2019 - 2021")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Remote healthcare software solution that facilitates seamless virtual consultations, remote patient monitoring, and secure medical data exchange, ensuring efficient and accessible healthcare services from the comfort of patients' homes. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media",attrs:{"data-background-style":"video"}},[_c('div',{staticClass:"video jarallax",attrs:{"data-speed":"0","data-jarallax-video":"https://www.youtube.com/watch?v=DFf37yQHDn8"}}),_c('div',{staticClass:"image jarallax",attrs:{"data-speed":"0","data-img-url":"img/about/2.jpg"}}),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])
}]

export { render, staticRenderFns }