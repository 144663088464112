<template>
  <fragment>
    <div class="mouse-cursor cursor-outer"></div>
    <div class="mouse-cursor cursor-inner"></div>
  </fragment>
</template>

<script>
export default {
  name: "Mouse",
};
</script>
