<template>
  <div class="aali_tm_section" id="about">
    <div class="aali_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.jpg"></div>
            <span
              class="square moving_effect"
              data-direction="y"
              data-reverse="yes"
            ></span>
            <span class="border"
              ><span
                class="in moving_effect"
                data-direction="x"
                data-reverse="yes"
              ></span
            ></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">About Me</span>
            <h3>I'm <span>Tasib</span></h3>
            <h3>A <span>Dev</span>Ops<span> Engineer</span></h3>
            <h3>and <span>Architect</span></h3>
            <span class="subtitle">Based in Lahore, PK</span>
          </div>
          <div
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <p>
              A DevOps specialist, highly passionate about my work.
            </p>
            <p>
              With 6+ years' experience as a professional 
              DevOps engineer and architect, I bring the 
              expertise needed to ensure project success.
            </p>
          </div>
          <div
            class="aali_tm_button wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
          >
            <a href="cv/Tasib-Ali.pdf" download>
              <span v-html="download"> </span
            ></a>
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/2.jpg"></div>
            <div
              class="square moving_effect"
              data-direction="x"
              data-reverse="no"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "./svgImage";
export default {
  name: "About",
  data() {
    return {
      download: `Download CV ${download}`,
    };
  },
};
</script>
