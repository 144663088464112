<template>
  <div class="aali_tm_section">
    <div class="aali_tm_skills">
      <div class="container">
        <div class="skills_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="light"
            >
              <span>My Skills</span>
              <h3>
                Where imagination meets execution:</h3><br />
                <h2>Count on Me to Make it Happen
              </h2>
              <p>
                As a highly proficient and seasoned DevOps specialist, I bring to the table a 
                rich array of technical expertise and extensive years of hands-on experience. 
                My skill set encompasses a wide spectrum of abilities that play a pivotal role 
                in facilitating seamless software development and deployment processes. With a
                keen focus on efficiency, reliability, and innovation, I adeptly navigate the 
                complexities of software development and operations, bolstering the overall 
                success of projects and making substantial contributions to the organization's 
                growth and advancement.
              </p>
              <p>Comprehensive skill set across various cloud computing platforms, 
                containerization technologies, and DevOps practices. I have experience 
                in managing and deploying cloud infrastructures on AWS, Microsoft Azure, 
                and Google Cloud Platform. My competence in Kubernetes includes EKS, AKS, 
                GKE, LKE, and on-premises deployment. I am proficient in Docker and 
                Docker-Compose, enabling me to design and maintain container environments. 
                I excel in CI/CD, ensuring streamlined and automated software development 
                processes. Additionally, I have experience in Infrastructure as Code using 
                Terraform, CloudFormation, and CDK. My mastery in system and network 
                administration extends to macOS, Linux, and Windows environments, allowing
                me to manage diverse IT infrastructures. I complement these abilities with 
                strong scripting skills in Bash and Python, enabling me to automate tasks 
                and improve operational efficiency.
              </p>
            </div>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="dodo_progress">

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">AWS - Amazon Web Services</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="85">
                <span
                  ><span class="label">Microsoft Azure - Cloud Computing Services</span
                  ><span class="number">85%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="80">
                <span
                  ><span class="label">GCP - Google Cloud Platform</span
                  ><span class="number">80%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">Kubernetes - EKS | AKS | GKE | LKE | On-Premises</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">Docker | Docker-Compose</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">Continuous Integration Continuous Deployment (CI/CD)</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="85">
                <span
                  ><span class="label">IaC - Terraform | CloudFormation | CDK</span
                  ><span class="number">85%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">System & Network Administration - MAC|Linux|Windows</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>

              <div class="progress_inner skillsInner___" data-value="90">
                <span
                  ><span class="label">Scripting - Bash | Python </span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <span
        class="border moving_effect"
        data-direction="x"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { activeSkillProgress } from "../utils/utils";

export default {
  name: "Skills",
  mounted() {
    window.addEventListener("scroll", activeSkillProgress);
  },
};
</script>
