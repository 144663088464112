<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
        <span>Empower Your Connections</span>
        <h3>Reach Out Today with Confidence</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>
                Please fill out the form on this section to contact Or
                Call between 9:00 A.M. and 8:00 P.M, Monday through Friday!
              </p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Call me</span>
                  <h3>+92 322 980 7840</h3>` + call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:hello@tasibali.com'
                      >hello@tasibali.com</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                @submit.prevent="sendEmail"
              >
                <div
                  class="empty_notice"
                  data-success="Your message has been received, We will contact you soon."
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <span class="contact_success">
                    Your message has been received, We will contact you soon.
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="text"
                        v-model="formData.name"
                        placeholder="Your Name"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="email"
                        v-model="formData.email"
                        placeholder="Your Email"
                      />
                    </li>
                    <li>
                      <input
                        id="phone"
                        type="text"
                        placeholder="Your Phone"
                        name="phn"
                        v-model="formData.phone"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    placeholder="Your message here"
                    v-model="formData.msg"
                  ></textarea>
                </div>
                <div class="tasib_tm_button">
                  <button  id="send_message" type="submit">
                    <span v-html="'Submit Message  ' + msgSent"></span>
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { call, msg, msgSent } from "./svgImage";
import emailjs from 'emailjs-com';

export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      formData: {
        name: "",
        email: "",
        phone: "",
        msg: "",
      },
      error: false,
      success: false,
    };
  },
  methods: {
    sendEmail() {
      this.error = false;
      this.success = false;
      if (!this.formData.name || !this.formData.email || !this.formData.msg) {
        this.error = true;
        return;
      }

      const templateParams = {
        from_name: this.formData.name,
        from_email: this.formData.email,
        to_name: "Tasib",
        message: this.formData.msg,
        phone: this.formData.phone
      };

      emailjs.send("service_2d0ml77", "template_7zcp6ju", templateParams, "qV9QAsnCuRinuoDGC")
        .then((response) => {
          console.log("Email sent successfully!", response);
          this.success = true;
          this.formData.name = "";
          this.formData.email = "";
          this.formData.phone = "";
          this.formData.msg = "";
        })
        .catch((error) => {
          console.log("Failed to send email:", error);
        });
    },
  },
};
</script>
